export const CSV_HEADERS = [
  'first_name',
  'last_name',
  'password',
  'email',
  'office',
  'advanced_office',
  'admin',
  'mobile',
  'advanced_mobile',
  'partsmanager_office',
  'quickstart',
];

export const beatify = (str = '') => str.replace(/"|"|'|' /g, '').trim();

export const groupByEmail = function (arr) {
  let result = [];

  arr.forEach(element => {
    const { userId, email, firstName, lastName, progress, lastLoginDate } = element;
    const item = result.find(el => el.email === email);
    if (!item) {
      result.push({ id: userId, email, firstName, lastName, progress, lastLoginDate, count: 1 });
    } else {
      const newItem = {
        ...item,
        count: item.count + 1,
        progress: item.progress + progress,
        lastLoginDate: item.lastLoginDate > lastLoginDate ? item.lastLoginDate : lastLoginDate,
      };
      result = result.filter(e => e.email !== email);
      result.push(newItem);
    }
  });

  return result.map(el => {
    return { ...el, progress: Math.floor(el.progress / el.count) };
  });
};

export const DEFAULT_COURSE_IDS = {
  Basic_Office: 'Basic_Office',
  Admin_Office: 'Admin_Office',
  Mobile: 'Mobile',
  Advanced_Mobile: 'Advanced_Mobile',
  Partsledger_Office: 'Partsledger_Office',
  QuickStart: 'QuickStart',
  Advanced_Office: 'Advanced_Office',
};

export const DEFAULT_COURSES = [
  {
    id: DEFAULT_COURSE_IDS.Basic_Office,
    name: 'Basic Office',
    shortName: 'Office',
    description: 'This course will cover usage of ServiceTrade and day-to-day processes.',
    duration: '4 Hrs',
  },
  {
    id: DEFAULT_COURSE_IDS.Advanced_Office,
    name: 'Advanced Office Features Certification',
    shortName: 'Advanced Office',
    description: 'Optional for advanced feature office users',
    duration: '2 Hr',
  },
  {
    id: DEFAULT_COURSE_IDS.Admin_Office,
    name: 'Admin Office',
    shortName: 'Admin',
    description: 'This course will cover advanced settings and pages in ServiceTrade.',
    duration: '1 Hr',
  },
  {
    id: DEFAULT_COURSE_IDS.Mobile,
    name: 'Mobile',
    shortName: 'Mobile',
    description: 'This course will cover the field processes for the techs.',
    duration: '1.5 Hr',
  },
  {
    id: DEFAULT_COURSE_IDS.Advanced_Mobile,
    name: 'Advanced Mobile',
    shortName: 'Advanced Mobile',
    description: 'This course will cover the advanced field processes for the techs.',
    duration: '30 Min',
  },
  {
    id: DEFAULT_COURSE_IDS.Partsledger_Office,
    name: 'PartsManager Office (if you purchased PartsManager)',
    shortName: 'PartsManager Office',
    description: 'This course will cover the advanced field processes for the techs.',
    duration: '30 Min',
  },
  {
    id: DEFAULT_COURSE_IDS.QuickStart,
    name: 'ServiceTrade QuickStart Course',
    shortName: 'QuickStart',
    description: 'ServiceTrade QuickStart Course',
    duration: '??? Min',
  },
];

class UserDetails {
  constructor() {
    this.details = {};
  }

  create() {
    this.details.id = null;
    this.details.email = '';
    this.details.firstName = '';
    this.details.lastName = '';
    this.details.password = '';
    this.details.expiresOn = '1900-01-01';
    this.details.enrollments = DEFAULT_COURSES.map(element => {
      return {
        ...element,
        value: null,
      };
    });

    return this.details;
  }
}

export const defineUserDetails = () => {
  return new UserDetails().create();
};

class CourseSelection {
  constructor() {
    this.courses = {};
  }

  create(list, value) {
    list.forEach(item => {
      this.courses[item] = value;
      return item;
    });

    return this.courses;
  }
}

export const defineDefaultCourseSelection = (value = true) => {
  return new CourseSelection().create(
    DEFAULT_COURSES.map(item => item.id),
    value,
  );
};

export const defaultCourses = DEFAULT_COURSES.map(item => item.id);
